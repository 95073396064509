export default [
  {
    path: '/client/invoices',
    name: 'ClientInvoices',
    component: () =>
      import(
        /* webpackChunkName: "ClientInvoices" */ '../views/Client/Invoices.vue'
      ),
  },
  {
    path: '/client/account',
    name: 'ClientAccount',
    component: () =>
      import(
        /* webpackChunkName: "ClientAccount" */ '../views/Client/Account.vue'
      ),
  },
  {
    path: '/client/roadmap',
    name: 'ClientRoadmap',
    component: () =>
      import(
        /* webpackChunkName: "ClientRoadmap" */ '../views/Client/Roadmap.vue'
      ),
  },
  {
    path: '/client/roadmap/sessions/:id',
    name: 'ClientShowSession',
    component: () =>
      import(
        /* webpackChunkName: "ClientShowSession" */ '../views/Client/ShowSession.vue'
      ),
  },
  {
    path: '/client/roadmap/sessions/:id/create-assignment',
    name: 'ClientCreateAssignment',
    component: () =>
      import(
        /* webpackChunkName: "ClientCreateAssignment" */ '../views/Client/Assignment/CreateAssignment.vue'
      ),
  },
  {
    path: '/client/roadmap/sessions/:id/assignments/:assignmentId',
    name: 'ClientShowAssignment',
    component: () =>
      import(
        /* webpackChunkName: "ClientShowAssignment" */ '../views/Client/Assignment/ShowAssignment.vue'
      ),
  },
  {
    path: '/client/roadmap/sessions/:id/create-goal',
    name: 'ClientCreateGoal',
    component: () =>
      import(
        /* webpackChunkName: "ClientCreateGoal" */ '../views/Client/Goal/CreateGoal.vue'
      ),
  },
  {
    path: '/client/roadmap/sessions/:id/goals/:goalId',
    name: 'ClientShowGoal',
    component: () =>
      import(
        /* webpackChunkName: "ClientShowGoal" */ '../views/Client/Goal/ShowGoal.vue'
      ),
  },
  {
    path: '/client/roadmap/sessions/:id/forms/:formId',
    name: 'ClientCreateFormEntry',
    component: () =>
      import(
        /* webpackChunkName: "ClientCreateFormEntry" */ '../views/Client/Form/CreateFormEntry.vue'
      ),
  },
  {
    path: '/client/diary',
    name: 'ClientDiary',
    component: () =>
      import(/* webpackChunkName: "ClientDiary" */ '../views/Client/Diary.vue'),
  },
  {
    path: '/client/diary/create-entry',
    name: 'ClientCreateDiaryEntry',
    component: () =>
      import(
        /* webpackChunkName: "ClientCreateDiaryEntry" */ '../views/Client/CreateDiaryEntry.vue'
      ),
  },
  {
    path: '/client/diary/:id',
    name: 'ClientShowDiaryEntry',
    component: () =>
      import(
        /* webpackChunkName: "ClientShowDiaryEntry" */ '../views/Client/ShowDiaryEntry.vue'
      ),
  },
  {
    path: '/client/chat',
    name: 'ClientChat',
    component: () =>
      import(
        /* webpackChunkName: "ClientChat" */ '../views/Shared/ChatView.vue'
      ),
  },
  {
    path: '/client/chat/:id',
    name: 'ShowChat',
    component: () =>
      import(
        /* webpackChunkName: "ShowChat" */ '../views/Shared/ShowChatView.vue'
      ),
  },
]
