import VuexPersistence from 'vuex-persist'
import { Role, ThemeColor } from '@/shared/types'
import ThemeService from '@/service/ThemeService'
import { createStore } from 'vuex'

class RootState {}

const vuexLocal = new VuexPersistence<RootState>({
  storage: window.localStorage,
})

const defaultUser = {
  role: '' as Role,
  settings: {
    primaryColor: ThemeService.getDefaultThemeColor() as ThemeColor,
    darkMode: false as boolean,
  },
}

export const store = createStore({
  state: {
    authenticated: false,
    user: defaultUser,
  },
  mutations: {
    changeAuthenticatedStatus(state, payload) {
      state.authenticated = payload.authenticated
    },
    changeDarkMode(state, payload) {
      state.user.settings.darkMode = payload.darkMode
    },
    changePrimaryColor(state, payload) {
      state.user.settings.primaryColor = payload.primaryColor
    },
    updateUser(state, payload) {
      const copyReferenceUser = state.user
      state.user = Object.assign({}, state.user, payload.newUser)

      state.user.settings = copyReferenceUser.settings
      state.user.settings.darkMode = payload.newUser.settings.dark_mode
      state.user.settings.primaryColor = payload.newUser.settings.primary_color
    },
    logout(state) {
      state.authenticated = false
      state.user = defaultUser
    },
  },
  actions: {},
  modules: {},
  plugins: [vuexLocal.plugin],
})
