import {
  ThemeColor,
  ThemeColor1,
  ThemeColor2,
  ThemeColor3,
  ThemeColor4,
} from '@/shared/types'

export default class ThemeService {
  public static getThemeColor1(): ThemeColor1 {
    return '#ffdac3'
  }

  public static getThemeColor2(): ThemeColor2 {
    return '#d6db7f'
  }

  public static getThemeColor3(): ThemeColor3 {
    return '#cbc5b8'
  }

  public static getThemeColor4(): ThemeColor4 {
    return '#b8f3ea'
  }

  public static getDefaultThemeColor(): ThemeColor {
    return this.getThemeColor4()
  }
}
