<script lang="ts">
  import { defineComponent } from 'vue'
  import { useTheme } from '@/composables/useTheme'

  export default defineComponent({
    name: 'App',
    computed: {
      rootClass() {
        const { setRootClass } = useTheme()
        let classString = setRootClass()

        if (this.$route.name === 'ClientRoadmap') classString += ' roadmap'

        classString += ` ${String(this.$route.name)}`

        return classString
      },
    },
  })
</script>

<template>
  <div class="app" :class="rootClass">
    <RouterView v-slot="{ Component }">
      <Transition name="fade" mode="out-in">
        <Component :is="Component" />
      </Transition>
    </RouterView>
  </div>
</template>

<style lang="scss">
  @import '~@/assets/fonts';
  @import '~@/assets/_utility.scss';
  @import '~@/variables.scss';

  .scroll-lock {
    overflow: hidden !important;
  }

  :root {
    --white: #fafafa;
    --black: #17181a;
    --off-black: #212121;
    --background-color: #fafafa;
    --primary-color: #{$primary-color};
    --primary-font-color: #{$dark};
    --primary-inverse-font-color: #fff;
    --secondary-font-color: #8a8a88;
    --card-color: #f2f2f2;
    --card-color-with-opacity: rgba(246, 246, 246, 1.8);
    --color-background: var(--white);
    --color-background-rgba: 255, 255, 255;
    --color-text: var(--off-black);
    --color-contrast-text: var(--white);
    --color-background-card: #f2f2f2;
  }

  :root.dark-mode {
    --background-color: #17181a;
    --primary-color: #{$primary-color};
    --primary-font-color: #fff;
    --primary-inverse-font-color: #{$dark};
    --secondary-font-color: rgb(255, 255, 255, 0.5);
    --card-color: #202223;
    --card-color-with-opacity: rgba(32, 34, 35, 0.8);
    --color-background: var(--black);
    --color-background-rgba: 23, 24, 26;
    --color-text: var(--white);
    --color-background-card: #202020;
  }

  :root.theme-1 {
    --theme-dependent-font-color: #{$dark};
  }

  :root.theme-2 {
    --theme-dependent-font-color: #{$dark};
  }

  :root.theme-3 {
    --theme-dependent-font-color: #{$dark};
  }

  :root.theme-4 {
    --theme-dependent-font-color: #{$dark};
  }

  :root.theme-5 {
    --theme-dependent-font-color: #fff;
  }

  :root.theme-6 {
    --theme-dependent-font-color: #fff;
  }

  :root.theme-7 {
    --theme-dependent-font-color: #fff;
  }

  :root.theme-8 {
    --theme-dependent-font-color: #fff;
  }

  html {
    box-sizing: border-box;
    font-size: 15px;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
    -webkit-tap-highlight-color: transparent;
  }

  body {
    font-family: 'DM Sans', sans-serif;
    line-height: 1.5;
    color: var(--primary-font-color);
    background-color: var(--background-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    min-height: 100vh;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Recoleta', sans-serif;
    line-height: 1.3;
    font-weight: 700;
    color: var(--primary-font-color);
    margin: 0;
  }

  p {
    margin: 0;
  }

  .app {
    min-height: calc(100vh - 150px);
    padding-bottom: 150px;
    overflow-x: hidden;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .dark-mode {
    a {
      color: #a7abf6;
    }
  }

  .ShowChat {
    .header-container {
      svg.logo {
        display: none;
      }
    }
  }

  .header-container {
    svg {
      cursor: pointer;
    }
  }

  .nav-exit-container {
    max-width: 1000px;
    padding: 0 24px;
    position: relative;
    margin: 0 auto;

    svg {
      position: absolute;
      right: 25px;
      top: 37px;
      height: 25px;
      cursor: pointer;

      @media (max-width: $max-mobile-width) {
        right: 26px;
        top: 27px;
      }
    }

    .theme-1 &,
    .theme-2 &,
    .theme-3 &,
    .theme-4 & {
      svg {
        fill: #323232;
      }
    }

    .theme-5 &,
    .theme-6 &,
    .theme-7 &,
    .theme-8 & {
      svg {
        fill: #fafafa;
      }
    }
  }

  .fade-enter-active {
    transition-duration: 0.2s;
    transition-property: opacity;
    transition-timing-function: ease-in-out;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }

  #client-nav-desktop,
  #coach-nav-desktop {
    @media (max-width: $max-mobile-width) {
      display: none;
    }

    z-index: 1;
    position: absolute;
    right: 120px;

    .icon-fill {
      fill: var(--theme-dependent-font-color);
    }

    ul {
      list-style: none;

      li {
        display: inline-block;
        margin-left: 45px;

        .router-link-active {
          position: relative;

          &:after {
            content: '';
            position: absolute;
            background: var(--theme-dependent-font-color);
            width: 4px;
            height: 4px;
            border-radius: 50%;
            bottom: -5px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }

  #coach-notifications {
    position: relative;
    text-decoration: none;
    color: inherit;

    .count {
      font-size: 0.75rem;
      position: absolute;
      top: -13px;
      left: 13px;
      font-weight: 600;
    }
  }

  .ShowChat {
    header {
      position: fixed !important;
      z-index: 100;
    }
  }

  .roadmap {
    padding-bottom: 0 !important;
    min-height: 100vh;
    background-color: var(--primary-color) !important;

    header {
      position: fixed !important;
      top: 0;
      z-index: 100;
      background-color: transparent;

      &:before {
        background-color: transparent;
        box-shadow: none;
      }
    }

    .header__bg {
      display: none;
    }

    footer {
      box-shadow: none !important;
      background-color: transparent !important;
    }
  }

  .v-popper__inner {
    max-width: 350px !important;
  }

  .roadmap {
    padding-bottom: 0;

    header {
      nav {
        svg {
          fill: var(--theme-dependent-font-color) !important;
        }
      }

      footer {
        svg {
          fill: var(--theme-dependent-font-color) !important;
        }

        ul {
          li {
            .router-link-active {
              &:after {
                background-color: var(--theme-dependent-font-color) !important;
              }
            }
          }
        }
      }
    }
  }
</style>
