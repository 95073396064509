import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { store } from '@/store'
import coach from '@/router/coach'
import client from '@/router/client'
import { Role } from '@/shared/types'
import { nextTick } from 'vue'

const getRoutes = (): Array<RouteRecordRaw> => {
  return [
    {
      path: '/',
      component: () =>
        import(
          /* webpackChunkName: "AuthenticationLayout" */ '../layouts/AuthenticationLayout.vue'
        ),
      children: [
        {
          path: '/login',
          name: 'LoginView',
          component: () =>
            import(
              /* webpackChunkName: "LoginView" */ '../views/LoginView.vue'
            ),
          meta: {
            title: 'Inloggen',
          },
        },
        {
          path: '/register',
          name: 'RegisterView',
          component: () =>
            import(
              /* webpackChunkName: "RegisterView" */ '../views/RegisterView.vue'
            ),
          meta: {
            title: 'Registeren',
          },
        },
        {
          path: '/forgot-password',
          name: 'ForgotPassword',
          component: () =>
            import(
              /* webpackChunkName: "ForgotPassword" */ '../views/ForgotPasswordView.vue'
            ),
          meta: {
            title: 'Wachtwoord vergeten',
          },
        },
        {
          path: '/reset-password',
          name: 'ResetPasswordView',
          component: () =>
            import(
              /* webpackChunkName: "ForgotPassword" */ '../views/ResetPasswordView.vue'
            ),
          meta: {
            title: 'Wachtwoord resetten',
            noAuth: true,
          },
        },
        {
          path: '/coach/register',
          name: 'CoachRegister',
          component: () =>
            import(
              /* webpackChunkName: "CoachRegister" */ '../views/Coach/Register.vue'
            ),
          meta: {
            title: 'Registreer je als coach',
          },
        },
        {
          path: '/client/register',
          name: 'ClientRegister',
          component: () =>
            import(
              /* webpackChunkName: "ClientRegister" */ '../views/Client/Register.vue'
            ),
          meta: {
            title: 'Registreer je als coachee',
          },
        },
      ],
    },
    {
      path: '/',
      component: () =>
        import(
          /* webpackChunkName: "DefaultLayout" */ '../layouts/DefaultLayout.vue'
        ),
      children: [
        {
          path: '/settings/password',
          name: 'PasswordSettings',
          component: () =>
            import(
              /* webpackChunkName: "PasswordSettings" */ '../views/Common/Settings/Password.vue'
            ),
        },
        {
          path: '/settings/two-factor',
          name: 'TwoFactorAuth',
          component: () =>
            import(
              /* webpackChunkName: "TwoFactorAuth" */ '../views/Common/Settings/TwoFactorAuth.vue'
            ),
        },
        {
          path: '/settings/screen',
          name: 'ScreenSettings',
          component: () =>
            import(
              /* webpackChunkName: "ScreenSettings" */ '../views/Common/Settings/Screen.vue'
            ),
        },
        {
          path: '/system/coaches',
          name: 'SystemCoaches',
          component: () =>
            import(
              /* webpackChunkName: "SystemCoaches" */ '../views/Common/SystemCoaches.vue'
            ),
        },
        {
          path: 'media-library',
          name: 'MediaLibraryView',
          component: () => import('../views/Shared/MediaLibraryView.vue'),
        },
        ...coach,
        ...client,
      ],
    },
    {
      path: '/',
      component: () =>
        import(
          /* webpackChunkName: "VideoLayout" */ '../layouts/VideoLayout.vue'
        ),
      children: [
        {
          path: '/video-room/:id',
          name: 'VideoRoom',
          component: () =>
            import(
              /* webpackChunkName: "VideoRoom" */ '../views/Shared/ShowVideoRoomView.vue'
            ),
        },
      ],
    },
  ]
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: getRoutes(),
})

type isRole = (expected: Role) => (actual: Role) => boolean
const isRole: isRole = (expected) => (actual) => actual === expected

const isCoach = isRole('Coach')
const isClient = isRole('Client')

const homeForCoach = 'CoachPlanning'
const homeForClient = 'ClientRoadmap'

router.beforeEach((to, from, next) => {
  const role = store.state.user.role

  if (
    !to.meta.noAuth &&
    to.name !== 'LoginView' &&
    to.name !== 'RegisterView' &&
    to.name !== 'CoachRegister' &&
    to.name !== 'ClientRegister' &&
    to.name !== 'ForgotPassword' &&
    !store.state.authenticated
  ) {
    next({ name: 'LoginView' })
    return
  }

  if (to.path.startsWith('/client') && isCoach(role)) {
    return next({ name: homeForCoach })
  }

  if (to.path.startsWith('/coach') && isClient(role)) {
    return next({ name: homeForClient })
  }

  if (
    to.path === '/' ||
    (to.path === '/login' && store.state.authenticated) ||
    (to.path === '/register' && store.state.authenticated) ||
    (to.path === '/client/register' && store.state.authenticated) ||
    (to.path === '/coach/register' && store.state.authenticated) ||
    (to.path === '/forgot-password' && store.state.authenticated)
  ) {
    const role = store.state.user.role
    const nextName = role === 'Coach' ? 'CoachPlanning' : 'ClientRoadmap'

    next({ name: nextName })
    return
  }

  next()
})

router.afterEach((to) => {
  const defaultTitle = 'Moot Coaching Software'

  const documentTitle = to.meta.title
    ? `${to.meta.title} | ${defaultTitle}`
    : defaultTitle

  nextTick(() => {
    document.title = documentTitle
  }).then()
})

export default router
