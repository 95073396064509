import { useStore } from 'vuex'

export const useTheme = () => {
  const store = useStore()

  const hasDarkMode = (): boolean => store.state.user.settings.darkMode
  const getPrimaryColor = (): string => store.state.user.settings.primaryColor

  const setRootClass = () => {
    const primaryColor = getPrimaryColor()
    const classes = []
    const rootClasses = []

    if (primaryColor === '#cbc5b8') {
      classes.push('theme-1')
      rootClasses.push('theme-1')
    } else if (primaryColor === '#ffdac3') {
      classes.push('theme-2')
      rootClasses.push('theme-2')
    } else if (primaryColor === '#d6db7f') {
      classes.push('theme-3')
      rootClasses.push('theme-3')
    } else if (primaryColor === '#2c716e') {
      classes.push('theme-5')
      rootClasses.push('theme-5')
    } else if (primaryColor === '#d25e39') {
      classes.push('theme-6')
      rootClasses.push('theme-6')
    } else if (primaryColor === '#4d4642') {
      classes.push('theme-7')
      rootClasses.push('theme-7')
    } else if (primaryColor === '#869e31') {
      classes.push('theme-8')
      rootClasses.push('theme-8')
    } else {
      classes.push('theme-4')
      rootClasses.push('theme-4')
    }

    if (hasDarkMode()) {
      rootClasses.push('dark-mode')
    }

    document.documentElement.className = rootClasses.join(' ')

    return rootClasses.join(' ')
  }

  return {
    hasDarkMode,
    getPrimaryColor,
    setRootClass,
  }
}
