import { createI18n } from 'vue-i18n'

export const i18n = createI18n({
  locale: 'nl',
  legacy: false,
  messages: {
    nl: {
      global: {
        hello: 'Hallo wereld!',
      },
    },
    en: {
      global: {
        hello: 'Hallo world!',
      },
    },
    de: {
      global: {
        hello: 'Hallo welt!',
      },
    },
  },
})
