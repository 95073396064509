import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import { store } from '@/store'
import axios from 'axios'
import * as Sentry from '@sentry/vue'
import { VTooltip } from 'floating-vue'
import 'floating-vue/dist/style.css'
import { i18n } from '@/plugins/i18n'
import draggable from 'vuedraggable'

axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}/api`
axios.defaults.withCredentials = true

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (401 === error.response.status) {
      store.commit('logout')
      location.reload()
    }
  },
)

if (store?.state?.user?.settings?.primaryColor) {
  document.documentElement.style.setProperty(
    '--primary-color',
    store.state.user.settings.primaryColor,
  )
}

if (store?.state?.user?.settings?.darkMode) {
  document.documentElement.className = 'dark-mode'
}

const app = createApp(App)
app.component('Draggable', draggable)
app.use(router)
app.use(store)
app.use(i18n)
app.directive('tooltip', VTooltip)
app.mixin({
  methods: {
    navigateTo(route: string): void {
      if (this.$route.path === route) {
        return
      }

      this.$router.push(route)
    },
  },
})
app.mount('#app')

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    app,
    release: 'moot-web-app@' + process.env.PACKAGE_VERSION,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [],
    tracesSampleRate: 0,
  })
}
